import {Injectable} from '@angular/core';
import GrayScale from "./functions/GrayScale";
import BigCursor from "./functions/BigCursor";
import {Router, Event, NavigationEnd} from '@angular/router';
import Reader from "./functions/Reader";
import ChangeContrast from "./functions/ChangeContrast";
import DyslexicFont from "./functions/DyslexicFont";
import HighlightLinks from "./functions/HighlightLinks";
import ReadingMask from "./functions/ReadingMask";
import VerticalSpacing from "./functions/VerticalSpacing";
import HorizontalSpacing from "./functions/HorizontalSpacing";
import ReadingGuide from "./functions/ReadingGuide";

/**
 * Servicio para las funciones
 * de accesibilidad.
 **/
@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {

  // Definicion de las clases de cada
  // funcionalidad
  private classDefinitions = [
    GrayScale,
    Reader,
    BigCursor,
    ChangeContrast,
    DyslexicFont,
    ReadingMask,
    ReadingGuide,
    HighlightLinks,
    VerticalSpacing,
    HorizontalSpacing,
  ];

  // ID del elemento padre donde a todos los
  // hijos se les aplicarán las funcionalidades
  private parentId = "main-container-cms";

  // Propiedad donde se almacena
  // el padre
  public parent: any = {} as Element;

  // Objecto del LS para almacenar
  public localStorage: Array<any> = [];

  // Objecto de todas las funcionalidades
  // disponibles
  public options: Array<any> = []


  // En el contructor, inicializamos el
  // localstorage, creamos el array de opciones
  // y llamamos al listener de la ruta
  constructor(private router: Router) {
    this.initLocalStorage();
    this.createOptions();
    
  }


  // Listener para aplicar las funciones
  // cuando se cambie la ruta
  private routerListener() {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.changeActivatedOptions();
        },500)
      }
    })
  }

  // En algunas funciones, se necesita el padre
  // y esta funcion carga el padre definido en esta clase
  // en la respectiva funcionalidad. Tambien se carga el
  // nombre del componente, si es que lo tiene.
  private async loadDependenciesToClasses() {
    for (const option of this.options) {
      await option.action.setParent(this.parent)
      if (option.action.hasComponent)
        option.componentName
          = option.action.componentName ?? "";
    }
  }

  // Este es llamado en OnInit en el componente
  // y es para asegurar que las funciones se aplican
  // cuando ya esta renderizada la vista y sus
  // elementos
  public async onInit() {
    this.parent = await document.getElementById(
      this.parentId
    )
    await this.loadDependenciesToClasses()
    this.routerListener();
    this.changeActivatedOptions();
  }

  // Validamos que la clase de funcionalidad tenga
  // las propiedades definidad en needed
  // esto, para poder agregar la funcion como una
  // opcion del menu
  private checkClassProperties(className: any) {
    const needed = ["name", "description", "icon"];
    let someFalse = false;
    needed.forEach(nd => {
      if (!className.hasOwnProperty(nd)) {
        someFalse = true;
      }
    })
    return !someFalse
  }


  // Creamos las opciones, cargando la informacion
  // a partir de su clase.
  private createOptions() {
    this.classDefinitions.forEach(className => {
      if (this.checkClassProperties(className)) {
        let lsItem = this.localStorage.find(
          item => item.name === className.name
        );
        let action = new className();
        action.controlNumber = lsItem ? lsItem.controlNumber : 1;
        this.options.push({
          name: className.itemName,
          description: className.description,
          icon: className.icon,
          active: lsItem ? lsItem.active : false,
          action: action,
          extraCssClases: className.extraCssClases
        })
      }
    });
  }

  // Almacenar el localstorage objecto
  private saveLocalStorage() {
    localStorage.setItem("_accessibility", JSON.stringify(
      this.localStorage
    ))
  }

  // Inicializar el objecto localstorage y si
  // no esta creado, se crea
  private initLocalStorage(): void {
    let local = localStorage.getItem("_accessibility")
    let generated = this.localStorage = this.classDefinitions.map(item =>
      Object({name: item.name, active: false, controlNumber: 1})
    );
    if (!local) {
      this.localStorage = generated
      this.saveLocalStorage();
      return
    }
    this.localStorage = JSON.parse(local)
  }

  // Para aplicar las funciones a todas aquellas
  // que esten activadas
  private changeActivatedOptions() {
    this.options.forEach(option => {
      if (option.active) {
        this.changeStatusOption(option)
      }
    })
  }


  // Switch para activar o desactivar la funcion.
  // La clase la funcion debe tener los dos metodos
  // de activar y desactivar.
  public changeStatusOption(option: any) {
    let actionName = option.active ? "activate" : "deactivate";
    let className = option.action.constructor.name || null
    let hasAction = option.action[actionName]
    if (className && hasAction) {
      let lsItem = this.localStorage.find(item =>
        item.name === className
      );
      if (!lsItem) {
        this.localStorage.push({
          name: className,
          active: option.active
        })
      } else {
        lsItem.active = option.active
      }
      option.action[actionName]();
      this.saveLocalStorage();
    }
  }

  // SI la funcionalidad tiene control por botones,
  // se necesitan los dos metodos de plus, y minus.
  // se llama a esta funcion para llamar los respectivos metodos
  // y tambien para almacenar los valores de <controlNumber>
  // en el localstorage
  public changeControlNumber(option: any, minusOrPlus: string) {
    if (option.active) {
      if (option.action[minusOrPlus]) {
        let className = option.action.constructor.name || null
        option.action[minusOrPlus]()
        let lsItem = this.localStorage.find(item =>
          item.name === className
        );
        if (!lsItem) {
          this.localStorage.push({
            name: className,
            active: option.active,
            controlNumber: option.action.controlNumber
          })
        } else {
          lsItem.active = option.active
          lsItem.controlNumber = option.action.controlNumber
        }
        this.changeStatusOption(option)
      }
    }
  }
}

