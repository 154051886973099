import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  slides = [
    // { 'image': './assets/gdl_img/banner_pago_predial.png', 'link': 'https://presupuestoparticipativo.guadalajara.gob.mx/encuesta/publico' },
    // { 'image': './assets/gdl_img/banner_aniversario.jpg', 'link': 'https://guadalajara.gob.mx/481aniversario/' },
    // { 'image': './assets/gdl_img/banner_gdl.png' },
    { 'image': './assets/gdl_img/banner_cencos_economicos_2024.jpg' },
    { 'image': './assets/gdl_img/banner_evaluacion_tramites.jpg', 'link': 'https://mejoraregulatoria.guadalajara.gob.mx/encuestas' },
    { 'image': './assets/gdl_img/banner_consulta_publica_2024.jpg', 'link': 'https://consultapublica.imeplan.mx/' },
    { 'image': './assets/gdl_img/banner_plan_desarrollo_2021_2024.jpg', 'link': 'https://guadalajara.gob.mx/plan-municipal-de-desarrollo-2021-2024/' },
    { 'image': './assets/gdl_img/banner_mejora_regulatoria.png', 'link': 'https://mejoraregulatoria.guadalajara.gob.mx/' },
    { 'image': './assets/gdl_img/banner_web_GobAbierto.jpg', 'link': 'https://transparencia.guadalajara.gob.mx/gobierno-abierto' },
    // { 'image': './assets/gdl_img/banner_certificacion_web-chico.png', 'link': 'https://guadalajaragob.mx/servicios-medicos/certificate-en-servicios-de-atencion-medica-prehospitalaria/' },
    { 'image': './assets/gdl_img/banner_DIGITRAMITE.png', 'link': 'https://visorurbano.guadalajara.gob.mx/' },
    // { 'image': './assets/gdl_img/banner_capital_mundial_del_libro.png', 'link': 'https://gdlee.mx/' },
    { 'image': './assets/gdl_img/banner_cero_tolerancia.jpg', 'link': 'https://guadalajara.gob.mx/politicacero/' },
    // { 'image': './assets/gdl_img/banner_gestion_integral_de_residuos.jpg', 'link': 'https://guadalajara.gob.mx/gdlWeb/#/detalle/258/Programa-Gestion-Integral-Residuos-Base-Cero/' },
    // { 'image': './assets/gdl_img/banner_transparencia.png', 'link': 'https://transparencia.guadalajara.gob.mx/' },
    // { 'image': './assets/gdl_img/banner_te_protejo.jpeg', 'link': 'https://www.teprotejo.org/' },
    { 'image': './assets/gdl_img/banner_mirada_pub_mesa_de_trabajo_1.jpg', 'link': 'https://miradapublica.guadalajara.gob.mx/' },
    { 'image': './assets/gdl_img/Convocatoria-Reconocimiento-Jose-Guizar-BANNER-WEB.png', 'link': 'https://guadalajara.gob.mx/docs/dictamen-convocatoria-consejo-de-participacion.pdf' }
  ];

  constructor() { }

  ngOnInit() {
  }

  goToLink() {
    debugger;
  }

}
