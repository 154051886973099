import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tesomovil',
  templateUrl: './tesomovil.component.html',
  styleUrls: ['./tesomovil.component.css']
})

export class TesomovilComponent implements OnInit {
  visitas = 4200;
  ubicacionesTesomovil = [];
  constructor(private http: HttpClient) { this.getVisitas(); this.getUbicacionesTesomovil(); }

  ngOnInit() { }


  getVisitas() {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/visitas?apartado_publicacion=vista&id_publicacion=0&link_publicacion=tesomovil')
    .subscribe( (result: any) => {
      if (result[0].status) {
        this.visitas += parseInt(result[0].visitas);
        console.log(result[0]);
      } else {
        //window.location.href = '#/notfound';
      }
    });
  }

  getUbicacionesTesomovil() {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/tesomovil')
    .subscribe( (result: any) => {
      this.ubicacionesTesomovil = result;
    });
  }

}