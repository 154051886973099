import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-opd',
  templateUrl: './opd.component.html',
  styleUrls: ['./opd.component.css']
})
export class OpdComponent implements OnInit {
  opds: any[] = [];
  aviso = '';

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getOpd(global.url_controller);
   }

  ngOnInit() {
  }

  getOpd(url_controller:any) {
    //this.http.get(url_controller+'opd')
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/opd')
    .subscribe( (result: any) => {
      if (result.status) {
        this.opds = result.data;
        if (this.opds.length === 0 ) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        console.log(result);
      } else {
        
      }
    });
  }

  cuerpo(text: any) {
    return text.substring(0, 300) + '...';
  }

}
