import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeHtml, Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../../seo.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';

// tslint:disable-next-line:use-pipe-transform-interface
@Component({
  selector: 'app-detalle-noticia',
  templateUrl: './detalle-noticia.component.html',
  styleUrls: ['./detalle-noticia.component.css']
})

export class DetalleNoticiaComponent implements OnInit, SafeResourceUrl {

  idNoticia;
  linkNoticia;
  tituloNoticia:string='';
  fechaNoticia:string='';
  imagenNoticia:string='';
  textoNoticia:string='';
  page: number = 1;
  totalPages: number=0;
  isLoaded: boolean = false;
  //url = null;
  url:any = '';
  pdfView = false;
  visitas = 0;


  imageObject: Array<object> = [];

  tiene_galeria_imagenes = 'N';
  // tslint:disable-next-line:variable-name
  // tslint:disable-next-line:max-line-length

  constructor(private meta: Meta, private seoService: SeoService, private route: ActivatedRoute, private http: HttpClient,
    public sanitizer: DomSanitizer, private titleService: Title,  private router: Router, location: Location) {
    this.idNoticia = this.route.snapshot.paramMap.get('id');
    this.linkNoticia = this.route.snapshot.paramMap.get('link');
    console.log(this.idNoticia);
    // this.getNew();
    this.titleService.setTitle('Noticias Guadajalara');
  }


  afterLoadComplete() {
    return 'sadasd';
  }

  ngOnInit() {
    this.getNew();
  }

  getNew() {
    // tslint:disable-next-line:max-line-length
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/item/getNoticia?id_noticia=' + this.idNoticia + '&link_noticia=' + this.linkNoticia)
      .subscribe((result: any) => {
        if (result.status) {
          if (result[0].activa == 'N')
            this.router.navigate([`/`]);
          this.tituloNoticia = result[0].nombre_noticia;
          this.fechaNoticia = this.fecha(result[0].fecha_noticia);
          this.imagenNoticia = result[0].imagen_noticia;
          this.textoNoticia = result[0].texto_noticia;
          this.visitas = result[0].visitas;
          // tslint:disable-next-line:max-line-length
          this.meta.updateTag({ name: 'imagen', property: 'og:image', content: 'https://guadalajara.gob.mx/media/noticias/' + this.imagenNoticia });
          if (result[0].urlPDF_Noticia !== '') {
            if (result[0].urlPDF_Noticia && result[0].urlPDF_Noticia != 'undefined' && result[0].urlPDF_Noticia != '')
              this.url = 'https://cdn.guadalajara.gob.mx/pdfGDL/' + result[0].urlPDF_Noticia;
            this.pdfView = true;
          } else {
            this.pdfView = false;
          }

          if (result[0].galeria_imagenes) {
            this.getGaleriaImagenes();
          }
          console.log(result[0]);

        } else {
          window.location.href = '#/notfound';
        }
      });
  }

  public fecha(fecha: string) {
    console.log("Esta es la fecha");
    const newDate = new Date(fecha);
    newDate.setDate(newDate.getDate() + 1);
    console.log(newDate);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    console.log(newDate.toLocaleString('es', options));
    return newDate.toLocaleString('es', options);
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  createLinkForCanonicalURL(url: string) {
    this.seoService.createLinkForCanonicalURL(url);
  }

  getGaleriaImagenes() {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/item/getGaleriaImagenes?id_publicacion=' + this.idNoticia)
      .subscribe((result: any) => {
        if (result.status) {
          this.tiene_galeria_imagenes = 'S';

          result[0].forEach((element:any) => {
            this.imageObject.push({
              image: 'https://guadalajara.gob.mx/media/noticias/' + element.file,
              thumbImage: 'https://guadalajara.gob.mx/media/noticias/' + element.file,
              alt: element.descripcion,
              title: element.titulo
            });
          });
          console.log(result[0]);
        } else {
          this.tiene_galeria_imagenes = 'N';
        }
      });
  }


}
