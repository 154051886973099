import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-gobierno',
  templateUrl: './gobierno.component.html',
  styleUrls: ['./gobierno.component.css']
})

export class GobiernoComponent implements OnInit {
  noticiaPrincipal: any[] = [];
  sesionPrincipal: any[] = [];
  noticias: any[] = [];
  conferencias: any[] = [];
  sesiones: any[] = [];
  arrayNoticias: any[] = [];
  arrayConferencias: any[] = [];
  arraySesiones: any[] = [];
  aviso = '';

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getNoticias(global.url_controller);
    this.getConferencias(global.url_controller);
    this.getSesiones(global.url_controller);
  }

  ngOnInit() { }

  getNoticias(url_controller: any) {
    this.http.get(url_controller + 'noticias?limite=4')
      .subscribe((result: any) => {
        this.noticias = result;
        if (this.noticias.length === 0) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.arrayNoticias = this.noticias.slice(0, 20);
        this.noticiaPrincipal = this.arrayNoticias[0];
      });
  }

  getConferencias(url_controller: any) {
    this.http.get(url_controller + 'videos?tipo=Conferencias&limite=4')
      .subscribe((result: any) => {
        this.conferencias = result;
        if (this.conferencias.length === 0) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.arrayConferencias = this.conferencias.slice(0, 20);
        console.log(this.arrayConferencias);
      });
  }

  getSesiones(url_controller: any) {
    this.http.get(url_controller + 'videos?tipo=Sesion&limite=3')
      .subscribe((result: any) => {
        this.sesiones = result;
        if (this.sesiones.length === 0) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.arraySesiones = this.sesiones.slice(0, 20);
        this.sesionPrincipal = this.arraySesiones[0];
      });
  }

  verPortal() {
    window.open("https://guadalajara.gob.mx/2016/", "_blank");
  }

  verNoticias() {
    window.open("https://guadalajara.gob.mx/gdlWeb/#/noticias", "_blank");
  }

  verTransparencia() {
    window.open("https://transparencia.guadalajara.gob.mx/", "_blank");
  }

  verConferencias() {
    window.open("https://livestream.com/accounts/15410192", "_blank");
  }
}
