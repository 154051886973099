import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';


@Component({
  selector: 'app-bolsa-trabajo-detalle',
  templateUrl: './bolsa-trabajo-detalle.component.html',
  styleUrls: ['./bolsa-trabajo-detalle.component.css']
})
export class BolsaTrabajoDetalleComponent implements OnInit {
  idEmpresa;
  linkEmpresa;
  trabajos: any[] = [];
  currentItemsToShow :any[] = [];
  catElement: any[] = [];
  catValue = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.idEmpresa = this.route.snapshot.paramMap.get('id');
    this.linkEmpresa = this.route.snapshot.paramMap.get('link');
    console.log(this.linkEmpresa);
    this.getJobs();
   }

   onPageChange($event:any) {
    // tslint:disable-next-line:whitespace
    this.currentItemsToShow =  this.trabajos.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  ngOnInit() {
  }

  getJobs() {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/bolsa/empleo_empresa?id_empresa=' + this.idEmpresa)
    .subscribe( (result: any) => {
      if (result.status) {
        this.trabajos = result[0];
        this.currentItemsToShow = this.trabajos.slice(0, 20);
        console.log(result);
      } else {
        window.location.href = '#/notfound';
      }
    });
  }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

  genero(genero:any) {
    let generoStr = '';
    switch ( genero ) {
      case 'I':
        generoStr = 'INDISTINTO';
        return generoStr;
      case 'F':
        generoStr = 'FEMENINO';
        return generoStr;
      case 'M':
        generoStr = 'MASCULINO';
        return generoStr;
      default:
        generoStr = 'NO ESPECIFICADO';
        return generoStr;
    }
  }

  public fecha(fecha: string) {
    const newDate = new Date(fecha);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    console.log(newDate.toLocaleString('es', options));
    return newDate.toLocaleString('es', options);
  }

  linkFormat(link:any) {
    const b = link.split('-').join(' ');
    const linkEmpresaFormat = b.replace(/[^áéíóúa-z0-9-]/gi, ' ');
    return linkEmpresaFormat;
  }

}
