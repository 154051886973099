import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'gdlWeb';
  //public url_controller = "http://localhost/gdlweb-admin-rest/index.php/api/";
  public url_controller = "https://aytoapi.guadalajara.gob.mx/rest/index.php/api/";
  

  ngOnInit(): void {

  }


}


