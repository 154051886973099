import BaseFunctionality from "../contracts/BaseFunctionality";
import FunctionInterface from "../contracts/FunctionInterface";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons";

class ReadingMask extends BaseFunctionality implements FunctionInterface {

  static itemName = "Mascara de lectura";
  static description = "Mascara de lectura";
  static icon = faBookOpen;
  public static extraCssClases = "col-xs-0";

  hasComponent = true;
  componentName = "app-accessibility-reading-mask";


  activate(): void {
    this.componentShow = true;
  }

  deactivate(): void {
    this.componentShow = false;
  }

};

export default ReadingMask;
