import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-coordinaciones',
  templateUrl: './coordinaciones.component.html',
  styleUrls: ['./coordinaciones.component.css']
})

export class CoordinacionesComponent implements OnInit {
  linkCoord;
  coordinacion: any[] = [];
  currentItemsToShow:any[]  = [];
  aviso  = '';
  imagen = '';
  nombre = '';
  link:string | null = '';
  
  constructor(private route: ActivatedRoute, private http: HttpClient, public global: AppComponent) {
    this.linkCoord = this.route.snapshot.paramMap.get('link');
    //this.getCoordDetail(global.url_controller);
    this.getDatos(this.linkCoord);
    this.link = this.linkCoord;
  }
  
  ngOnInit() { }

  getDatos(link:any){
    switch (link) {
      case 'comisaria-de-la-policia-de-guadalajara':
          this.nombre = 'Comisaría de la Policía de Guadalajara';
          this.imagen = 'comisariadeseguridadguadalajara.svg';
        break;
      case 'coordinacion-de-comunicacion-institucional':
          this.nombre = 'Coordinación de Comunicación Institucional';
          this.imagen = 'comunicacion_institucional.svg';
        break;
      case 'coordinacion-general-de-administracion-e-innovacion-gubernamental':
          this.nombre = 'Coordinación General de Administración e Innovación Gubernamental';
          this.imagen = 'administracioneinnovaciongubernamental.svg';
        break;
      case 'coordinacion-general-de-construccion-de-la-comunidad':
          this.nombre = 'Coordinación General de Construcción de la Comunidad';
          this.imagen = 'construcciondecomunidad.svg';
        break;
      case 'coordinacion-general-de-desarrollo-economico-y-combate-a-la-desigualdad':
          this.nombre = 'Coordinación General de Desarrollo Económico y Combate a la Desigualdad';
          this.imagen = 'desarrolloeconomicoycombatealadesigualdad.svg';
        break;
      case 'coordinacion-general-de-gestion-integral-de-la-ciudad':
          this.nombre = 'Coordinación General de Gestión Integral de la Ciudad';
          this.imagen = 'gestionintegraldelaciudad.svg';
        break;
      case 'coordinacion-general-de-servicios-municipales':
          this.nombre = 'Coordinación General de Servicios Municipales';
          this.imagen = 'serviciosmunicipales.svg';
        break;
      case 'secretaria-general':
          this.nombre = 'Secretaría General';
          this.imagen = 'secretariageneral.svg';
        break;
      case 'sindicatura':
          this.nombre = 'Sindicatura';
          this.imagen = 'sindicaturaguadalajara.svg';
        break;
      case 'tesoreria-municipal':
          this.nombre = 'Tesorería Municipal';
          this.imagen = 'tesoreriaguadalajara.svg';
        break;
      default:
        break;
    }
  }

  getCoordDetail(url_controller:any) {
    this.http.get(url_controller+'coordinaciones_link?coordinacion='+this.linkFormat(this.linkCoord))
    .subscribe( (result: any) => {
      this.coordinacion = result;
      if (this.coordinacion.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.coordinacion.slice(0, 20);
      this.imagen = this.coordinacion[0]['imagen_coordinacion'];
      this.nombre = this.coordinacion[0]['nombre_coordinacion'];
    });
  }

  linkFormat(link:any) {
    const b = link.split('-').join(' ');
    const linkCoordFormat = b.replace(/[^áéíóúa-z0-9-]/gi, ' ');
    return linkCoordFormat;
  }
}
