import FunctionInterface from "../contracts/FunctionInterface";
import {faDroplet} from "@fortawesome/free-solid-svg-icons";
import BaseFunctionality from "../contracts/BaseFunctionality";

class ChangeContrast extends BaseFunctionality implements FunctionInterface {

  public static itemName = "Cambiar el contraste del color";
  public static description = "Cambiar el contraste del color"
  public static icon = faDroplet;
  public static extraCssClases = "";

  public affectedElements = ["img"]
  public cssClassName = "invert-contrast";


  public activate() {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
    });
  }

  public deactivate() {
    this.getElements().forEach(element => {
      element.classList.remove(this.cssClassName)
    });
  }

}


export default ChangeContrast;
