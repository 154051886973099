import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private doc:any
    ) {}

  createLinkForCanonicalURL(url: string) {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('rel', 'image_src');
     link.setAttribute('href', url);
     this.doc.head.appendChild(link);
   }
}