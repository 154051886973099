import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-tramites',
  templateUrl: './tramites.component.html',
  styleUrls: ['./tramites.component.css']
})

export class TramitesComponent implements OnInit {

  constructor(private http: HttpClient, public global: AppComponent) {

  }

  ngOnInit() {
  }

}
