import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-comunicacion-institucional',
  templateUrl: './comunicacion-institucional.component.html',
  styleUrls: ['./comunicacion-institucional.component.css']
})

export class ComunicacionInstitucionalComponent implements OnInit {
  comunicados: any[] = [];
  sesiones:    any[] = [];
  audios:      any[] = [];
  imagenes:    any[] = [];

  arrayComunicados :any[] = [];
  arraySesiones :any[]    = [];
  arrayAudios :any[]      = [];
  arrayImagenes :any[]    = [];
  aviso :string           = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getComunicados(global.url_controller);
    //this.getSesiones(global.url_controller);
    //this.getAudios(global.url_controller);
    //this.getImagenes(global.url_controller);
  }

  ngOnInit() { }

  onPageChange($event:any) {
    this.arrayComunicados =  this.comunicados.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getComunicados(url_controller:any) {
    this.http.get(url_controller+'comunicados?limite=4')
    .subscribe( (result: any) => { 
      if (result.status) {
        this.aviso = '';
        this.comunicados = result[0];
      } else {
        this.aviso = 'No se encontraron resultados';
        this.comunicados = [];
      }
      //this.arrayComunicados = this.comunicados.slice(0, 20);
    });
  }

  getSesiones(url_controller:any) {
    this.http.get(url_controller+'videos?limite=4')
    .subscribe( (result: any) => {
      this.sesiones = result;
      if (this.sesiones.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.arraySesiones = this.sesiones.slice(0, 20);
    });
  }

  getAudios(url_controller:any) {
    this.http.get(url_controller+'audios?limite=6')
    .subscribe( (result: any) => {
      this.audios = result;
      if (this.audios.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.arrayAudios = this.audios.slice(0, 20);
    });
  }
  
  getImagenes(url_controller:any) {
    this.http.get(url_controller+'imagenes?limite=4')
    .subscribe( (result: any) => {
      this.imagenes = result;
      if (this.imagenes.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.arrayImagenes = this.imagenes.slice(0, 20);
    });
  }

  verComisiones() { window.open("#/comunicacion-institucional/comunicados", "_blank"); }
  verSesiones() { window.open("https://livestream.com/accounts/15410192", "_blank"); }
  verAudios() { window.open("https://soundcloud.com/guadalajaragob", "_blank"); }
  verFotos() { window.open("https://www.flickr.com/photos/guadalajaragob/albums", "_blank"); }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

}
