import { Component, EventEmitter, Input, OnInit, Output,AfterViewInit } from '@angular/core';
import { Image } from './image';

@Component({
  selector: 'random-banner',
  templateUrl: './random-banner.component.html',
  styleUrls: ['./random-banner.component.css']
})

export class RandomBannerComponent implements OnInit {

  @Input() path:string=''
  @Input() images:Array<Image>=[]
  @Input() usedImages:Array<Image>=[]
  @Output() setUsedImg:EventEmitter<Image> = new EventEmitter<Image>()

  randomImg:Image = {} as Image;

  constructor() {  }

  ngOnInit() {
    //diferencia entre array de imagenes original y usadas
    let diff = this.images.filter(item => this.usedImages.indexOf(item) < 0);
    this.randomImg = diff[Math.floor(Math.random() * diff.length)];
    this.useImg(this.randomImg)
  }

  useImg(img:Image){
    this.setUsedImg.emit(img)
  }
}
