import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResultModel } from './model';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-organigrama',
  templateUrl: './organigrama.component.html',
  styleUrls: ['./organigrama.component.css']
})

export class OrganigramaComponent implements OnInit {
  public marks: ResultModel[] = [new ResultModel()];  
  public marks2: ResultModel[] = [new ResultModel()];    

  organigrama: any[] = [];
  org_relaciones: any[] = [];
  arrayOrg = [];
  aviso = '';    

  title = "app";

  datos:any;
  updateFromInput = false;
  chartConstructor = "chart";
  chartCallback:any;
  chartOptions:any;
  constructor(private http: HttpClient, public global: AppComponent) { 
  }

  ngOnInit() { }

  // ------------------------------------------- //

  getChart(url_controller:any)
  {
    this.http.get(url_controller+'/organigrama')
    .subscribe( (result: any) =>
    { 
      this.marks = result;
      let chartData:any = []; 

      this.marks.forEach((elemen:any) => {
        chartData.push({
          id: elemen['id_cargo'], 
          title: elemen['cargo'], 
          name: elemen['nombre']
        });
      });

      this.http.get(url_controller+'/org_relaciones')
      .subscribe( (result2: any) =>
      { 

      });
    });
  }
}
