import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { MaterialExports } from './material';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { MatPaginatorIntlCro } from './paginatorLanguaje';
import { MatPaginatorIntl } from '@angular/material/paginator';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafePipe } from './custom.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

import { ComisariaComponent } from './components/comisaria/comisaria.component';
import { HistoriaComponent } from './components/historia/historia.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { DetalleNoticiaComponent } from './components/detalle-noticia/detalle-noticia.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BolsaTrabajoComponent } from './components/bolsa-trabajo/bolsa-trabajo.component';
import { BolsaTrabajoDetalleComponent } from './components/bolsa-trabajo-detalle/bolsa-trabajo-detalle.component';
import { GerenciaComponent } from './components/gerencia/gerencia.component';
import { GerenciaDetalleComponent } from './components/gerencia-detalle/gerencia-detalle.component';
import { DirectorioTelefonicoComponent } from './components/directorio-telefonico/directorio-telefonico.component';
import { DirectorioTelefonicoDetalleComponent } from './components/directorio-telefonico-detalle/directorio-telefonico-detalle.component';
import { CoordinacionDependenciasComponent } from './components/coordinacion-dependencias/coordinacion-dependencias.component';
import { TramitesComponent } from './components/tramites/tramites.component';
import { CoordinacionesComponent } from './components/coordinaciones/coordinaciones.component';
import { RegidoresComponent } from './components/regidores/regidores.component';
import { ComunicacionInstitucionalComponent } from './components/comunicacion-institucional/comunicacion-institucional.component';
import { GobiernoComponent } from './components/gobierno/gobierno.component';
import { SesionAyuntamientoComponent } from './components/sesion-ayuntamiento/sesion-ayuntamiento.component';
import { OrganigramaComponent } from './components/organigrama/organigrama.component';
import { ParquesJardinesComponent } from './components/parques-jardines/parques-jardines.component';
import { ConvocatoriasComponent } from './components/convocatorias/convocatorias.component';
import { OpdComponent } from './components/opd/opd.component';
import { ConoceComponent } from './components/conoce/conoce.component';
import { TesomovilComponent } from './components/tesomovil/tesomovil.component';

import { NgImageSliderModule } from 'ng-image-slider';
import { PrincipalComponent } from './components/principal/principal.component';
import { RegidoresContactoComponent } from './components/regidores-contacto/regidores-contacto.component';
import { ComunicadosComponent } from './components/comunicados/comunicados.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { SocialLogosComponent } from './components/social-logos/social-logos.component';
import { CardsComunicacionComponent } from './components/comunicacion-institucional/cards-comunicacion/cards-comunicacion.component';
import { RandomBannerComponent } from './components/random-banner/random-banner.component';
import { AccessibilityModule } from './common/services/components/accessibility-components/accessibility.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ComisariaComponent,
    FooterComponent,
    HistoriaComponent,
    NoticiasComponent,
    DetalleNoticiaComponent,
    NotFoundComponent,
    BolsaTrabajoComponent,
    BolsaTrabajoDetalleComponent,
    GerenciaComponent,
    GerenciaDetalleComponent,
    DirectorioTelefonicoComponent,
    DirectorioTelefonicoDetalleComponent,
    CoordinacionDependenciasComponent,
    TramitesComponent,
    CoordinacionesComponent,
    RegidoresComponent,
    GobiernoComponent,
    SesionAyuntamientoComponent,
    ComunicacionInstitucionalComponent,
    OrganigramaComponent,
    ParquesJardinesComponent,
    SafePipe,
    SanitizeHtmlPipe,
    ConvocatoriasComponent,
    OpdComponent,
    ConoceComponent,
    TesomovilComponent,
    PrincipalComponent,
    RegidoresContactoComponent,
    ComunicadosComponent,
    CarouselComponent,
    SocialLogosComponent,
    CardsComunicacionComponent,
    RandomBannerComponent,
    AvisoPrivacidadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AccessibilityModule,
    RouterModule.forRoot(ROUTES, {useHash: true}),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialExports,
    //PdfViewerModule,
    NgImageSliderModule,
    MatCarouselModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}],
  bootstrap: [AppComponent]
})

export class AppModule { }