import FunctionInterface from "../contracts/FunctionInterface";
import {faFont} from "@fortawesome/free-solid-svg-icons";
import BaseFunctionality from "../contracts/BaseFunctionality";

class DyslexicFont extends BaseFunctionality implements FunctionInterface {


  public static itemName = "Tipografía de dislexia";
  public static description = "Cambiar a tipo de letra optimizada para dislexia"
  public static icon = faFont
  public static extraCssClases = "";

  public affectedElements = [
    "p", "span", "button", "h1",
    "h2", "h3", "h4", "h5", "h6",
    "a", "li",
  ]
  public cssClassName = "font-dyslexic";



  public activate() {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
    });
  }

  public deactivate() {
    this.getElements().forEach(element => {
      element.classList.remove(this.cssClassName)
    });
  }

}


export default DyslexicFont;
