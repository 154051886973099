import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'app-accessibility-reading-guide',
  templateUrl: './accessibility-reading-guide.component.html',
  styleUrls: ['./accessibility-reading-guide.component.scss']
})
export class AccessibilityReadingGuideComponent {

  public marginTop = "0px";

  @HostListener("window:mousemove", ["$event"])
  onMouseMove(event:any) {
    this.marginTop = (event.clientY + 10 ) + 'px';
  }

}
