import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../../app.component';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.css']
})

export class ComunicadosComponent implements OnInit {
  comunicados: any[] = [];
  arrayComunicados :any[] = [];
  aviso = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getComunicados(global.url_controller); 
  }

  ngOnInit() {}

  onPageChange($event:any) {
    this.arrayComunicados =  this.comunicados.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getComunicados(url_controller:any) {
    this.http.get(url_controller+'comunicados?limite=4000')
    .subscribe( (result: any) => {
      if (result.status) {
        this.comunicados = result[0];
        if (this.comunicados.length === 0 ) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.arrayComunicados = this.comunicados.slice(0, 20);
      } else {
        window.location.href = '#/notfound';
      }
    });
  }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

  public fecha(fecha: string) {
    const newDate = new Date(fecha);
    newDate.setDate(newDate.getDate() + 1);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return newDate.toLocaleString('es', options);
  }

  cuerpo(text: string) {
    var cuerpo = jQuery(text).text();
    cuerpo = cuerpo.substring(0, 230) + '...';

    return cuerpo.trim();
  }
}