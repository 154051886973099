import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../../../app.component';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';
import * as jQuery from 'jquery' ;

@Component({
  selector: 'app-cards-comunicacion',
  templateUrl: './cards-comunicacion.component.html',
  styleUrls: ['./cards-comunicacion.component.css']
})


export class CardsComunicacionComponent implements OnInit {
  @Input() enableColors:boolean=false;
  //colors = ['#E50695','#319D5A','#8373D4','#FF647D']
  comunicados: any[] = [];
  aviso = '';

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getComunicados(global.url_controller);
  }
  ngOnInit(){}
  getComunicados(url_controller:any) {
    this.http.get(url_controller+'comunicados?limite=4')
    .subscribe( (result: any) => { 
      if (result.status) {
        this.aviso = '';
        this.comunicados = result[0];
      } else {
        this.aviso = 'No se encontraron resultados';
        this.comunicados = [];
      }
      //this.arrayComunicados = this.comunicados.slice(0, 20);
    });
  }
  public fecha(fecha: string) {
    const newDate = new Date(fecha);
    newDate.setDate(newDate.getDate() + 1);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return newDate.toLocaleString('es', options);
  }

  cuerpo(text: string) {
    var cuerpo = jQuery(text).text();
    cuerpo = cuerpo.substring(0, 230) + '...';

    return cuerpo.trim();
  }
}
