import {faArrowPointer} from "@fortawesome/free-solid-svg-icons";
import FunctionInterface from "../contracts/FunctionInterface";
import BaseFunctionality from "../contracts/BaseFunctionality";

class BigCursor extends BaseFunctionality implements FunctionInterface{


  public static itemName = "Aumentar tamaño de cursor";
  public static description = "Aumentar el tamaño del cursor"
  public static icon = faArrowPointer
  public static extraCssClases = "col-xs-0";

  public needParent = false;
  public affectedElements = ["html"]
  public cssClassName = "big-cursor";
  


  public activate() {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
    });
  }

  public deactivate() {
    this.getElements().forEach(element => {
      element.classList.remove(this.cssClassName)
    });
  }
}

export default BigCursor;
