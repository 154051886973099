import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conoce',
  templateUrl: './conoce.component.html',
  styleUrls: ['./conoce.component.css']
})
export class ConoceComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    // this.router.navigate([`/`]); // Temporal hasta definir al presidente suplente
    this.createMetaTag();
  }

  createMetaTag() {
    const meta = document.createElement('meta');
    meta.setAttribute('property','og:image'); //this line is the issue
    meta.content = './assets/gdl_img/BARBARA_TRIGUEROS.jpg';
    document.getElementsByTagName('head')[0].appendChild(meta);
}

}
