import {AccessibilityComponent} from "./accessibility.component";
import {NgModule} from "@angular/core";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {
  AccessibilityReadingMaskComponent
} from "./components/accessibility-reading-mask/accessibility-reading-mask.component";
import {FormsModule} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { AccessibilityReadingGuideComponent } from "./components/accessibility-reading-guide/accessibility-reading-guide.component";

@NgModule({
  declarations: [
    AccessibilityComponent,
    AccessibilityReadingMaskComponent,
    AccessibilityReadingGuideComponent
  ],
  imports: [
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    CommonModule,
  ],
  exports: [
    AccessibilityComponent,
  ]
})
export class AccessibilityModule {
}
