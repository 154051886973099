import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import FunctionInterface from "../contracts/FunctionInterface";
import BaseFunctionality from "../contracts/BaseFunctionality";

class Reader extends BaseFunctionality implements FunctionInterface {

  public static itemName = "Lector en pantalla";
  public static description = "Lector en pantalla";
  public static icon = faVolumeHigh;
  public static extraCssClases = "";


  public affectedElements = [
    "p", "h1", "h2", "h3", "h4", "h5", "h6",
    "span", "button", "a", "img", "li"
  ]
  public cssClassName = "reader-cursor-pointer"


  public static speech: SpeechSynthesisUtterance;

  constructor() {
    super();
    Reader.speech = new SpeechSynthesisUtterance();
    Reader.speech.lang = "es-US";
  }


  public listenerAction(event: any) {
    let speechTxt = Reader._getSpeechText(event);
    Reader.speech.text = speechTxt;
    Reader.speechText();
  }

  private static _getSpeechText(event: any): string {
    let txt = event.target.innerText ?? '';
    if (event.target.localName == 'img')
      txt = event.target.alt;
    return txt;
  }

  activate(): void {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
      element.addEventListener("mouseover", this.listenerAction)
    })
  }

  deactivate(): void {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
      element.removeEventListener("mouseover", this.listenerAction)
      window.speechSynthesis.cancel()
    })
  }

  static speechText() {
    window.speechSynthesis.cancel()
    window.speechSynthesis.speak(Reader.speech)
  }
}

export default Reader;
