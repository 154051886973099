import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-parques-jardines',
  templateUrl: './parques-jardines.component.html',
  styleUrls: ['./parques-jardines.component.css'],
})

export class ParquesJardinesComponent implements OnInit {
  parques_jardines: any[] = [];
  arrayPJ = [];
  aviso = '';

  constructor(private http: HttpClient) {
    this.getPJ();
  }

  ngOnInit() {}

  getPJ() {
   
  }
}
