import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-sesion-ayuntamiento',
  templateUrl: './sesion-ayuntamiento.component.html',
  styleUrls: ['./sesion-ayuntamiento.component.css']
})

export class SesionAyuntamientoComponent implements OnInit {
  sesionPrincipal: any[] = [];
  sesiones: any[]        = [];
  arraySesiones: any[]   = [];
  aviso:string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) { 
    this.getSesiones(global.url_controller);
  }

  ngOnInit() { }

  onPageChange($event:any) {
    this.arraySesiones =  this.sesiones.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getSesiones(url_controller:any) {
    this.http.get(url_controller+'videos?tipo=Sesion')
    .subscribe( (result: any) => {
      this.sesiones = result;
      if (this.sesiones.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.arraySesiones = this.sesiones.slice(0, 9);
      this.sesionPrincipal = this.arraySesiones[0];
    });
  }
}
