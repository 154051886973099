import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSelect } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Image } from '../random-banner/image';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';
import * as jQuery from 'jquery';
import { AccessibilityModule } from 'src/app/common/services/components/accessibility-components/accessibility.module';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})

export class PrincipalComponent implements OnInit {
  noticias: any[] = [];
  currentItemsToShow: any[] = [];
  aviso = '';
  dias_restantes = 0;

  randomBannerPath = '../../../assets/gdl_img/random_banners/'
  randomBannerImgs = [
    { name: 'banner_te_quiero_mucho_pag_web_mesa_de_trabajo_1_small.png', link: '#' },
    { name: 'banner_pag_web_matrimonios_colectivos_mesa_de_trabajo_1_small.png', link: '#' },
    { name: 'banner_mirada_pub_mesa_de_trabajo_1_small.jpg', link: '#' },
    { name: 'banner_al_son_que_me_toquen_pag web_mesa_de_trabajo_1_small.png', link: '#' },
    { name: 'banner_pag_cd_que_quiero_web_mesa_de_trabajo_1_small.png', link: '#' },
  ]
  randomBannerUsedImgs: Array<Image> = []
  tramitesDigitales = environment.tramitesDigitales;
  constructor(private route: ActivatedRoute, private http: HttpClient, public global: AppComponent) {
    // this.getNews(global.url_controller);
  }

  ngOnInit() {

    let fecha1 = new Date();
    let fecha2 = new Date('2022/04/23');

    let resta = fecha2.getTime() - fecha1.getTime();
    //console.log(Math.round(resta/ (1000*60*60*24)));

    this.dias_restantes = Math.floor(resta / (1000 * 60 * 60 * 24));
  }

  spliceUsedImg(img: Image) {
    console.log('imagen usada: ', this.randomBannerImgs.indexOf(img), img.name)
    //this.randomBannerImgs = this.randomBannerImgs.filter( (v,i) => i != this.randomBannerImgs.indexOf(img) )
    if (!this.randomBannerUsedImgs.includes(img)) {
      this.randomBannerUsedImgs.push(img)
    }
    //console.table(this.randomBannerImgs.splice(this.randomBannerImgs.indexOf(img),1))
  }

  getNews(url_controller: any) {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/item?id_tipo=7')
      .subscribe((result: any) => {
        if (result.status) {
          this.noticias = result[0];
          if (this.noticias.length === 0) {
            this.aviso = 'No se encontraron resultados';
          } else {
            this.aviso = '';
          }
          this.currentItemsToShow = this.noticias.slice(0, 4);
        } else {
          window.location.href = '#/notfound';
        }
      });
  }

  public fecha(fecha: string) {
    const newDate = new Date(fecha);
    newDate.setDate(newDate.getDate() + 1);
    const options: DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return newDate.toLocaleString('es', options);
  }

  cuerpo(text: string) {
    var cuerpo = jQuery(text).text();
    cuerpo = cuerpo.substring(0, 230) + '...';

    return cuerpo.trim();
  }

  linkMundialLibro() {
    window.location.href = 'http://www.gdlee.mx/';
  }

}
