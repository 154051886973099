import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core'
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  // tslint:disable-next-line:max-line-length
  imports: [
    MatButtonModule, 
    MatCheckboxModule,
    MatAutocompleteModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatListModule, 
    MatCardModule,
    MatDatepickerModule, 
    MatNativeDateModule, 
    ReactiveFormsModule, 
    MatSelectModule, 
    MatSnackBarModule, 
    MatPaginatorModule
  ],
  // tslint:disable-next-line:max-line-length
  exports: [MatButtonModule, 
    MatCheckboxModule, 
    MatAutocompleteModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatListModule, 
    MatCardModule,
    MatDatepickerModule, 
    MatNativeDateModule, 
    ReactiveFormsModule, 
    MatSelectModule, 
    MatSnackBarModule, 
    MatPaginatorModule],
})
export class MaterialExports {
  
}
