import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  // tslint:disable-next-line:variable-name
  constructor(private _sanitizer: DomSanitizer) { }

  transform(v: string ): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

}
