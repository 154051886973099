import BaseFunctionality from "../contracts/BaseFunctionality";
import FunctionInterface from "../contracts/FunctionInterface";
import {faRulerHorizontal} from "@fortawesome/free-solid-svg-icons";

class ReadingGuide extends BaseFunctionality implements FunctionInterface{

  static itemName = "Guia de lectura";
  static description = "Guia de lectura";
  static icon = faRulerHorizontal;
  public static extraCssClases = "col-xs-0";

  hasComponent = true;
  componentName = "app-accessibility-reading-guide";


  activate(): void {
    this.componentShow = true;
  }

  deactivate(): void {
    this.componentShow = false;
  }

}

export default ReadingGuide;
